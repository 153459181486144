import gql from 'graphql-tag'


const listResponse = `
  id type name converter
`

export const LIST_INVENTORY_CONVERTER = (templateType) => gql`query LIST_INVENTORY_CONVERTER ($docType: String!, $parentId: Int!) {
  items: listDoc${templateType}InventoryConverter (docType: $docType, parentId: $parentId) {${listResponse}}
}`

export const CREATE_INVENTORY_CONVERTER = (templateType) => gql`mutation CREATE_INVENTORY_CONVERTER ($docType: String!, $parentId: Int!, $input: Doc${templateType}InventoryConverterInput!) {
  item: createDoc${templateType}InventoryConverter (docType: $docType, parentId: $parentId, input: $input) {${listResponse}}
}`

export const DESTROY_INVENTORY_CONVERTER = (templateType) => gql`mutation DESTROY_INVENTORY_CONVERTER ($docType: String!, $docConfigId: Int!) {
  item: destroyDoc${templateType}InventoryConverter (docType: $docType, docConfigId: $docConfigId) {id}
}`
