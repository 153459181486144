import gql from 'graphql-tag'


const listResponse = `
  id type docType name isDefault
  costOfSalesId costOfSales {id code name}
  sellAccountReceivableId sellAccountReceivable {id code name}
`

export const LIST_ACCOUNT = (templateType) => gql`query LIST_ACCOUNT ($docType: String!, $type: String!, $q: FilterInput) {
  accounts: listDoc${templateType}Account (docType: $docType, type: $type, q: $q) {${listResponse}}
}`

const detailResponse = `
  id type docType name isDefault
  costOfSalesId costOfSales {id code name}
  sellAccountReceivableId sellAccountReceivable {id code name}
`

export const DETAIL_ACCOUNT = (templateType) => gql`query DETAIL_ACCOUNT ($docType: String!, $type: String!, $docConfigId: Int!) {
  account: detailDoc${templateType}Account (docType: $docType, type: $type, docConfigId: $docConfigId) {${detailResponse}}
}`

export const CREATE_ACCOUNT = (templateType) => gql`mutation CREATE_ACCOUNT ($docType: String!, $type: String!, $input: Doc${templateType}AccountInput!) {
  account: createDoc${templateType}Account (docType: $docType, type: $type, input: $input) {${detailResponse}}
}`

export const UPDATE_ACCOUNT = (templateType) => gql`mutation UPDATE_ACCOUNT ($docType: String!, $type: String!, $docConfigId: Int!, $input: Doc${templateType}AccountInput!) {
  account: updateDoc${templateType}Account (docType: $docType, type: $type, docConfigId: $docConfigId, input: $input) {${detailResponse}}
}`

export const DESTROY_ACCOUNT = (templateType) => gql`mutation DESTROY_ACCOUNT ($docType: String!, $type: String!, $docConfigId: Int!) {
  account: destroyDoc${templateType}Account (docType: $docType, type: $type, docConfigId: $docConfigId) {id}
}`
