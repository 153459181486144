import gql from 'graphql-tag'


const listResponse = `
  id type cron
`

export const LIST_CONTACT_BILLED_AT = (templateType) => gql`query LIST_CONTACT_BILLED_AT ($docType: String!, $parentId: Int!) {
  items: listDoc${templateType}ContactBilledAt (docType: $docType, parentId: $parentId) {${listResponse}}
}`

export const CREATE_CONTACT_BILLED_AT = (templateType) => gql`mutation CREATE_CONTACT_BILLED_AT ($docType: String!, $parentId: Int!, $input: Doc${templateType}ContactBilledAtInput!) {
  item: createDoc${templateType}ContactBilledAt (docType: $docType, parentId: $parentId, input: $input) {${listResponse}}
}`

export const DESTROY_CONTACT_BILLED_AT = (templateType) => gql`mutation DESTROY_CONTACT_BILLED_AT ($docType: String!, $docConfigId: Int!) {
  item: destroyDoc${templateType}ContactBilledAt (docType: $docType, docConfigId: $docConfigId) {id}
}`
