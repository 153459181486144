import gql from 'graphql-tag'


export const EVALUATE_CONTACT = (templateType) => gql`query EVALUATE_CONTACT ($docType: String!, $q: FilterInput) {
  items: evaluateDoc${templateType}Contact (docType: $docType, q: $q) {
    id type code name branch
  }
}`

export const EVALUATE_SALES_CREDIT = (templateType) => gql`query EVALUATE_SALES_CREDIT ($docType: String!, $contactId: Int!) {
  items: evaluateDoc${templateType}SalesCredit (docType: $docType, contactId: $contactId) {
    id type name salesCredit isDefault
  }
}`

export const EVALUATE_INVENTORY = (templateType) => gql`query EVALUATE_INVENTORY ($docType: String!, $type: String!, $contactId: Int!, $expiredAt: DateTime, $q: FilterInput, $qty: Float) {
  items: evaluateDoc${templateType}Inventory (docType: $docType, type: $type, contactId: $contactId, expiredAt: $expiredAt, q: $q, qty: $qty) {
    id type name isDefault
    unitId unit {id name}
    salesPrice expiredAt qty
    children {
      id type name converter
    }
    parent {
      id type name
    }
  }
}`
