import gql from 'graphql-tag'


const listResponse = `
  id type docType name discountPrice isDefault
  unitId unit {id name}
  accountId account {id code name}
  contacts {
    id type
    contactId contact {id type code name branch}
  }
`

export const LIST_DISCOUNT_PRICE = (templateType) => gql`query LIST_DISCOUNT_PRICE ($docType: String!, $q: FilterInput) {
  discountPrices: listDoc${templateType}DiscountPrice (docType: $docType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id type docType name discountPrice isDefault
  unitId unit {id name}
  accountId account {id code name}
`

export const DETAIL_DISCOUNT_PRICE = (templateType) => gql`query DETAIL_DISCOUNT_PRICE ($docType: String!, $docConfigId: Int!) {
  discountPrice: detailDoc${templateType}DiscountPrice (docType: $docType, docConfigId: $docConfigId) {${detailResponse}}
}`

export const CREATE_DISCOUNT_PRICE = (templateType) => gql`mutation CREATE_DISCOUNT_PRICE ($docType: String!, $input: Doc${templateType}DiscountPriceInput!) {
  discountPrice: createDoc${templateType}DiscountPrice (docType: $docType, input: $input) {${detailResponse}}
}`

export const UPDATE_DISCOUNT_PRICE = (templateType) => gql`mutation UPDATE_DISCOUNT_PRICE ($docType: String!, $docConfigId: Int!, $input: Doc${templateType}DiscountPriceInput!) {
  discountPrice: updateDoc${templateType}DiscountPrice (docType: $docType, docConfigId: $docConfigId, input: $input) {${detailResponse}}
}`

export const DESTROY_DISCOUNT_PRICE = (templateType) => gql`mutation DESTROY_DISCOUNT_PRICE ($docType: String!, $docConfigId: Int!) {
  discountPrice: destroyDoc${templateType}DiscountPrice (docType: $docType, docConfigId: $docConfigId) {id}
}`
