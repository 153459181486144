import gql from 'graphql-tag'


const listResponse = `
  id type docType name
  startedAt expiredAt isDefault
  junctions {
    id tag isDefault
    qty price
    startedAt expiredAt
    childId child {
      id type name
      inventory {
        id unit {id name}
      }
    }
  }
`

export const LIST_GROUP = (templateType) => gql`query LIST_GROUP ($docType: String!, $q: FilterInput) {
  groups: listDoc${templateType}Group (docType: $docType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id type docType name isDefault
  startedAt expiredAt
`

export const DETAIL_GROUP = (templateType) => gql`query DETAIL_GROUP ($docType: String!, $docConfigId: Int!) {
  group: detailDoc${templateType}Group (docType: $docType, docConfigId: $docConfigId) {${detailResponse}}
}`

export const CREATE_GROUP = (templateType) => gql`mutation CREATE_GROUP ($docType: String!, $input: Doc${templateType}GroupInput!) {
  group: createDoc${templateType}Group (docType: $docType, input: $input) {${detailResponse}}
}`

export const UPDATE_GROUP = (templateType) => gql`mutation UPDATE_GROUP ($docType: String!, $docConfigId: Int!, $input: Doc${templateType}GroupInput!) {
  group: updateDoc${templateType}Group (docType: $docType, docConfigId: $docConfigId, input: $input) {${detailResponse}}
}`

export const DESTROY_GROUP = (templateType) => gql`mutation DESTROY_GROUP ($docType: String!, $docConfigId: Int!) {
  group: destroyDoc${templateType}Group (docType: $docType, docConfigId: $docConfigId) {id}
}`

export const LIST_GROUP_CONTACT = (templateType) => gql`query LIST_GROUP_CONTACT ($docType: String!, $docConfigId: Int!, $q: FilterInput) {
  items: listDoc${templateType}GroupContact (docType: $docType, docConfigId: $docConfigId, q: $q) {
    id type code name branch
  }
}`