import gql from 'graphql-tag'


const listResponse = `
  id type name isDefault
`

export const LIST_CONTACT_DISCOUNT_PRICE = (templateType) => gql`query LIST_CONTACT_DISCOUNT_PRICE ($docType: String!, $parentId: Int!) {
  discountPrices: listDoc${templateType}ContactDiscountPrice (docType: $docType, parentId: $parentId) {${listResponse}}
}`

export const CREATE_CONTACT_DISCOUNT_PRICE = (templateType) => gql`mutation CREATE_CONTACT_DISCOUNT_PRICE ($docType: String!, $parentId: Int!, $childId: Int!, $isDefault: Boolean) {
  discountPrice: createDoc${templateType}ContactDiscountPrice (docType: $docType, parentId: $parentId, childId: $childId, isDefault: $isDefault) {parentId childId}
}`

export const DESTROY_CONTACT_DISCOUNT_PRICE = (templateType) => gql`mutation DESTROY_CONTACT_DISCOUNT_PRICE ($docType: String!, $parentId: Int!, $childId: Int!) {
  discountPrice: destroyDoc${templateType}ContactDiscountPrice (docType: $docType, parentId: $parentId, childId: $childId) {parentId childId}
}`

export const DP_CONTACT_DISCOUNT_PRICE = (templateType) => gql`query DP_CONTACT_DISCOUNT_PRICE ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}DiscountPrice (docType: $docType, q: $q) {
    id type docType name
  }
}`
