import gql from 'graphql-tag'


const listResponse = `
  id type docType
  contactId contact {id type code name branch}
  groups {id type name}
  discountPrices {id type name}
  salesCredits {id type salesCredit isDefault}
  billings {id type cron}
`

export const LIST_CONTACT = (templateType) => gql`query LIST_CONTACT ($docType: String!, $q: FilterInput) {
  contacts: listDoc${templateType}Contact (docType: $docType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id type docType
  contactId contact {id type code name branch}
`

export const DETAIL_CONTACT = (templateType) => gql`query DETAIL_CONTACT ($docType: String!, $docConfigId: Int!) {
  contact: detailDoc${templateType}Contact (docType: $docType, docConfigId: $docConfigId) {${detailResponse}}
}`

export const CREATE_CONTACT = (templateType) => gql`mutation CREATE_CONTACT ($docType: String!, $input: Doc${templateType}ContactInput!) {
  contact: createDoc${templateType}Contact (docType: $docType, input: $input) {${detailResponse}}
}`

export const UPDATE_CONTACT = (templateType) => gql`mutation UPDATE_CONTACT ($docType: String!, $docConfigId: Int!, $input: Doc${templateType}ContactInput!) {
  contact: updateDoc${templateType}Contact (docType: $docType, docConfigId: $docConfigId, input: $input) {${detailResponse}}
}`

export const DESTROY_CONTACT = (templateType) => gql`mutation DESTROY_CONTACT ($docType: String!, $docConfigId: Int!) {
  contact: destroyDoc${templateType}Contact (docType: $docType, docConfigId: $docConfigId) {id}
}`

export const LIST_CONTACT_TYPE = (templateType) => gql`query LIST_CONTACT_TYPE ($docType: String!) {
  types: listDoc${templateType}ContactType (docType: $docType)
}`
