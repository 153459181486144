import gql from 'graphql-tag'


const listResponse = `
  id type name
`

export const LIST_CONTACT_GROUP = (templateType) => gql`query LIST_CONTACT_GROUP ($docType: String!, $parentId: Int!) {
  groups: listDoc${templateType}ContactGroup (docType: $docType, parentId: $parentId) {${listResponse}}
}`

export const CREATE_CONTACT_GROUP = (templateType) => gql`mutation CREATE_CONTACT_GROUP ($docType: String!, $parentId: Int!, $childId: Int!) {
  group: createDoc${templateType}ContactGroup (docType: $docType, parentId: $parentId, childId: $childId) {parentId childId}
}`

export const DESTROY_CONTACT_GROUP = (templateType) => gql`mutation DESTROY_CONTACT_GROUP ($docType: String!, $parentId: Int!, $childId: Int!) {
  group: destroyDoc${templateType}ContactGroup (docType: $docType, parentId: $parentId, childId: $childId) {parentId childId}
}`

export const DP_CONTACT_GROUP = (templateType) => gql`query DP_CONTACT_GROUP ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}Group (docType: $docType, q: $q) {
    id type docType name
  }
}`
