import gql from 'graphql-tag'


const listResponse = `
  id type isDefault
  contactId contact {id type code name}
`

export const LIST_CONTACT_SALES = (templateType) => gql`query LIST_CONTACT_SALES ($docType: String!, $parentId: Int!) {
  items: listDoc${templateType}ContactSales (docType: $docType, parentId: $parentId) {${listResponse}}
}`

export const CREATE_CONTACT_SALES = (templateType) => gql`mutation CREATE_CONTACT_SALES ($docType: String!, $parentId: Int!, $input: Doc${templateType}ContactSalesInput!) {
  item: createDoc${templateType}ContactSales (docType: $docType, parentId: $parentId, input: $input) {${listResponse}}
}`

export const DESTROY_CONTACT_SALES = (templateType) => gql`mutation DESTROY_CONTACT_SALES ($docType: String!, $docConfigId: Int!) {
  item: destroyDoc${templateType}ContactSales (docType: $docType, docConfigId: $docConfigId) {id}
}`

export const LIST_CONTACT_SALES_TYPE = (templateType) => gql`query LIST_CONTACT_SALES_TYPE ($docType: String!) {
  types: listDoc${templateType}ContactSalesType (docType: $docType)
}`