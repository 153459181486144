import gql from 'graphql-tag'


const listResponse = `
  id tag isDefault
  qty price
  startedAt expiredAt
  parentId parent {
    id type name
  }
  childId child {
    id type name
  }
`

export const LIST_GROUP_INVENTORY = (templateType) => gql`query LIST_GROUP_INVENTORY ($docType: String!, $type: String!, $parentId: Int!, $childId: Int, $q: FilterInput) {
  items: listDoc${templateType}GroupInventory (docType: $docType, type: $type, parentId: $parentId, childId: $childId, q: $q) {${listResponse}}
}`

export const CREATE_GROUP_INVENTORY = (templateType) => gql`mutation CREATE_GROUP_INVENTORY ($docType: String!, $type: String!, $parentId: Int!, $childId: Int!, $input: Doc${templateType}GroupInventoryInput!) {
  item: createDoc${templateType}GroupInventory (docType: $docType, type: $type, parentId: $parentId, childId: $childId, input: $input) {parentId childId}
}`

export const DESTROY_GROUP_INVENTORY = (templateType) => gql`mutation DESTROY_GROUP_INVENTORY ($docType: String!, $type: String!, $parentId: Int!, $docConfigId: Int!) {
  item: destroyDoc${templateType}GroupInventory (docType: $docType, type: $type, parentId: $parentId, docConfigId: $docConfigId) {parentId childId}
}`

export const DP_GROUP_INVENTORY = (templateType) => gql`query DP_GROUP_INVENTORY ($docType: String!, $type: String!, $q: FilterInput) {
  items: listDoc${templateType}Inventory (docType: $docType, type: $type, q: $q) {
    id type name
  }
}`
