import gql from 'graphql-tag'


const listResponse = `
  id type docType name salesPrice
  unitId unit {id name}
  accountId account {id code name}
`

export const LIST_EXPENSE = (templateType) => gql`query LIST_EXPENSE ($docType: String!, $q: FilterInput) {
  expenses: listDoc${templateType}Expense (docType: $docType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id type docType name salesPrice
  unitId unit {id name}
  accountId account {id code name}
`

export const DETAIL_EXPENSE = (templateType) => gql`query DETAIL_EXPENSE ($docType: String!, $docConfigId: Int!) {
  expense: detailDoc${templateType}Expense (docType: $docType, docConfigId: $docConfigId) {${detailResponse}}
}`

export const CREATE_EXPENSE = (templateType) => gql`mutation CREATE_EXPENSE ($docType: String!, $input: Doc${templateType}ExpenseInput!) {
  expense: createDoc${templateType}Expense (docType: $docType, input: $input) {${detailResponse}}
}`

export const UPDATE_EXPENSE = (templateType) => gql`mutation UPDATE_EXPENSE ($docType: String!, $docConfigId: Int!, $input: Doc${templateType}ExpenseInput!) {
  expense: updateDoc${templateType}Expense (docType: $docType, docConfigId: $docConfigId, input: $input) {${detailResponse}}
}`

export const DESTROY_EXPENSE = (templateType) => gql`mutation DESTROY_EXPENSE ($docType: String!, $docConfigId: Int!) {
  expense: destroyDoc${templateType}Expense (docType: $docType, docConfigId: $docConfigId) {id}
}`
