import gql from 'graphql-tag'


const listResponse = `
  id type docType name
  inventoryId inventory {
    id type code name
    unitId unit {id name}
  }
  parentId parent {id name}
`

export const LIST_INVENTORY = (templateType) => gql`query LIST_INVENTORY ($docType: String!, $type: String!, $q: FilterInput) {
  inventories: listDoc${templateType}Inventory (docType: $docType, type: $type, q: $q) {${listResponse}}
}`

const detailResponse = `
  id type docType name
  inventoryId inventory {id type code name}
  parentId parent {id name}
`

export const DETAIL_INVENTORY = (templateType) => gql`query DETAIL_INVENTORY ($docType: String!, $type: String!, $docConfigId: Int!) {
  inventory: detailDoc${templateType}Inventory (docType: $docType, type: $type, docConfigId: $docConfigId) {${detailResponse}}
}`

export const CREATE_INVENTORY = (templateType) => gql`mutation CREATE_INVENTORY ($docType: String!, $type: String!, $input: Doc${templateType}InventoryInput!) {
  inventory: createDoc${templateType}Inventory (docType: $docType, type: $type, input: $input) {${detailResponse}}
}`

export const UPDATE_INVENTORY = (templateType) => gql`mutation UPDATE_INVENTORY ($docType: String!, $type: String!, $docConfigId: Int!, $input: Doc${templateType}InventoryInput!) {
  inventory: updateDoc${templateType}Inventory (docType: $docType, type: $type, docConfigId: $docConfigId, input: $input) {${detailResponse}}
}`

export const DESTROY_INVENTORY = (templateType) => gql`mutation DESTROY_INVENTORY ($docType: String!, $type: String!, $docConfigId: Int!) {
  inventory: destroyDoc${templateType}Inventory (docType: $docType, type: $type, docConfigId: $docConfigId) {id}
}`

export const LIST_INVENTORY_TYPE = (templateType) => gql`query LIST_INVENTORY_TYPE ($docType: String!, $type: String!) {
  types: listDoc${templateType}InventoryType (docType: $docType, type: $type)
}`

export const DP_INVENTORY_ACCOUNT = (templateType) => gql`query DP_INVENTORY_ACCOUNT ($docType: String!, $type: String!, $q: FilterInput) {
  items: listDoc${templateType}Account (docType: $docType, type: $type, q: $q) {
    id type name
  }
}`
